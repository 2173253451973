*,
*:before,
*:after {
    box-sizing: border-box;
}

@font-face {
    font-family: "APJapanesefontImportant";
    src:
        local("APJapanesefontImportant"),
        url(./assets/fonts/APJapanesefont.ttf) format("truetype");
}

@font-face {
    font-family: "APJapanesefont";

    src:
        local("APJapanesefont"),
        url(./assets/fonts/APJapanesefont.ttf) format("truetype");
    unicode-range: U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F, U+3400-4DB5;
}

@font-face {
    font-family: "CenturyGothicPaneuropean";
    src:
        local("CenturyGothicPaneuropean"),
        url(./assets/fonts/CenturyGothicPaneuropeanRegular.ttf) format("truetype");
}

html {
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    width: 100%;

    scrollbar-width: thin;
    scrollbar-color: rgba(90, 90, 90, 0.3) rgba(0, 0, 0, 0);
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;
}

body {
    font-family: "CenturyGothicPaneuropean", Arial, Helvetica, sans-serif;

    min-height: 100%;
    height: 100%;
    margin: 0;
    overflow-y: scroll;
    /* background: linear-gradient(180deg, #ffffff 5%, rgba(255, 255, 255, 0) 75%),
        linear-gradient(0deg, #ffffffc7 0%, rgba(255, 255, 255, 0) 35%),
        linear-gradient(100.24deg, #12d73068 0%, #a084caaf 56%); */
    background: linear-gradient(180deg, #ffffff 5%, rgba(255, 255, 255, 0) 75%),
        linear-gradient(0deg, #ffffffc7 0%, rgba(255, 255, 255, 0) 35%),
        linear-gradient(100.24deg, #fdfdfd68 0%, #f7cbe8e4 56%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

#root {
    max-width: 100dvw;
    background-attachment: fixed;

    min-height: 100vh;
    min-height: 100dvh;
    height: 100%;
}

.App {
    /* background-color: var(--css-var-bg); */

    /* --css-var-block: #bface0;
    --css-var-title: #a084ca;
    --css-var-other: #a084ca;; */
    min-height: 100vh;
    min-height: 100dvh;
    height: 100%;
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    --var-page-title-size: 56px;
}

.mainNavBar {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 4px;
}

.a-clear,
.a-clear:link,
.a-clear:visited,
.a-clear:focus,
.a-clear:hover,
.a-clear:active {
    outline: none;
    text-decoration: none;
}

.a-link,
.a-link:link,
.a-link:visited,
.a-link:focus,
.a-link:hover,
.a-link:active {
    outline: none;
    text-decoration: none;
    color: #000000;
}

.testBorder {
    border: #000000 solid 1px;
}

div.findPairSelected {
    background: rgb(255, 255, 0);
}

div.findPairCorrect {
    background: rgb(0, 255, 0);
}

.scrambleItem {
    width: 3em;
    height: 3em;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bg-placeholder {
    color: #6f6f6f;
}
