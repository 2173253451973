.wrapperActivity {
    min-width: 300px;
    width: 300px;
    height: 300px;
    position: relative;
    display: flex;
    /*OPTIONAL MARGIN*/
}

.textActivity {
    width: 100%;
    height: 100%;
    background: radial-gradient(
        ellipse at center,
        var(--css-var-card-bg) 0%,
        var(--css-var-card-bg) 69.8%,
        var(--css-var-card-bg) 70.1%,
        var(--css-var-card-bg) 70.6%,
        rgba(0, 0, 0, 0) 70.8%
    );
    position: relative;
    margin: 0;
}

.textActivity > div {
    height: 100%;
    font-size: 21px;
    line-height: 1.25;
    padding: 0;
    flex-direction: column;
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
}

.textActivity::before {
    content: "";
    width: 50%;
    height: 100%;
    float: left;
    shape-outside: polygon(
        0 0,
        98% 0,
        50% 6%,
        23.4% 17.3%,
        6% 32.6%,
        0 50%,
        6% 65.6%,
        23.4% 82.7%,
        50% 94%,
        98% 100%,
        0 100%
    );
    shape-margin: 7%;
}

.textActivity > div::before {
    content: "";
    width: 50%;
    height: 100%;
    float: right;
    shape-outside: polygon(
        2% 0%,
        100% 0%,
        100% 100%,
        2% 100%,
        50% 94%,
        76.6% 82.7%,
        94% 65.6%,
        100% 50%,
        94% 32.6%,
        76.6% 17.3%,
        50% 6%
    );
    shape-margin: 7%;
}
