.processing-page__button-block {
    display: flex;
    gap: 16px;
}

.processing-page__button-success {
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
}

.processing-page__button-delete {
    display: flex;
    flex: 0 1 auto;
}

.processing-page {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.processing-page__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.processing-page__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
