.laoding-page-container {
    display: flex;
    margin: 40px auto;
    justify-content: center;
}

.ldsDefaultContainer {
    width: 80px;
    height: 80px;
}

.ldsDefaultContainer[data-size="l"] {
    width: 120px;
    height: 120px;
}

.ldsDefaultContainer[data-size="xl"] {
    width: 160px;
    height: 160px;
}

.ldsDefaultContainer[data-size="xxl"] {
    width: 200px;
    height: 200px;
}

.ldsDefault {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
}
.ldsDefault div {
    position: absolute;
    width: 7.5%;
    height: 7.5%;
    background: #000000;
    border-radius: 50%;
    animation: ldsDefault 1.2s linear infinite;
}
.ldsDefault div:nth-child(1) {
    animation-delay: 0s;
    top: 46.25%;
    left: 82.5%;
}
.ldsDefault div:nth-child(2) {
    animation-delay: -0.1s;
    top: 27.5%;
    left: 77.5%;
}
.ldsDefault div:nth-child(3) {
    animation-delay: -0.2s;
    top: 13.75%;
    left: 65%;
}
.ldsDefault div:nth-child(4) {
    animation-delay: -0.3s;
    top: 8.75%;
    left: 46.25%;
}
.ldsDefault div:nth-child(5) {
    animation-delay: -0.4s;
    top: 13.75%;
    left: 27.5%;
}
.ldsDefault div:nth-child(6) {
    animation-delay: -0.5s;
    top: 27.5%;
    left: 13.75%;
}
.ldsDefault div:nth-child(7) {
    animation-delay: -0.6s;
    top: 46.25%;
    left: 8.75%;
}
.ldsDefault div:nth-child(8) {
    animation-delay: -0.7s;
    top: 65%;
    left: 13.75%;
}
.ldsDefault div:nth-child(9) {
    animation-delay: -0.8s;
    top: 77.5%;
    left: 27.5%;
}
.ldsDefault div:nth-child(10) {
    animation-delay: -0.9s;
    top: 82.5%;
    left: 46.25%;
}
.ldsDefault div:nth-child(11) {
    animation-delay: -1s;
    top: 77.5%;
    left: 65%;
}
.ldsDefault div:nth-child(12) {
    animation-delay: -1.1s;
    top: 65%;
    left: 77.5%;
}
@keyframes ldsDefault {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}
