.lessonCardBase {
    margin: 40px 0;
}

div.skillLesson {
    margin-left: 32px;
    min-width: 150px;
    max-width: 150px;
    border: 4px dotted #000000;
}

.linkLesson {
    padding: 10px;
    min-width: 250px;
    border-top-right-radius: 25px;
    background: var(--css-var-card-bg);
}

div.blockActivity {
    width: 200px;
    height: 200px;
    border: 1px solid #000000;
    border-radius: 50%;
    shape-outside: circle(50%);
}

.wrapperActivity {
    width: 300px;
    height: 300px;
    position: relative;
    margin: 10vh auto 0;
    /*OPTIONAL MARGIN*/
}

.textActivity {
    background: radial-gradient(
        ellipse at center,
        rgba(0, 128, 172, 1) 0%,
        rgba(0, 128, 172, 1) 70%,
        rgba(0, 128, 172, 0) 70.3%
    );
    height: 100%;
    width: 100%;

    color: white;
    position: relative;
    margin: 0;
}

.textActivity::before {
    content: "";
    float: left;
    height: 100%;
    width: 50%;
    shape-outside: polygon(
        0 0,
        98% 0,
        50% 6%,
        23.4% 17.3%,
        6% 32.6%,
        0 50%,
        6% 65.6%,
        23.4% 82.7%,
        50% 94%,
        98% 100%,
        0 100%
    );
    shape-margin: 7%;
}

.textActivity div::before {
    content: "";
    width: 50%;
    height: 100%;
    float: right;
    shape-outside: polygon(
        2% 0%,
        100% 0%,
        100% 100%,
        2% 100%,
        50% 94%,
        76.6% 82.7%,
        94% 65.6%,
        100% 50%,
        94% 32.6%,
        76.6% 17.3%,
        50% 6%
    );
    shape-margin: 7%;
}
