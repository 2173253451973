.pageTitle {
    font-family: "APJapanesefont", "CenturyGothicPaneuropean", Arial, Helvetica, sans-serif;
    line-height: 1;
    font-size: var(--var-page-title-size);
    padding: 5px 5px 5px 13px;

    overflow-wrap: anywhere;
    min-width: 160px;
    width: fit-content;
    margin: 20px auto;
    display: flex;
    border-radius: 2px;
    position: relative;
    text-align: center;

    align-items: center;
}

.pageTitleBack {
    height: var(--var-page-title-size);
    font-size: 32px;
    line-height: 1;
    background-color: #198754;
    border-radius: 4px;

    cursor: pointer;
    margin-right: 10px;
    margin-left: -5px;
    display: flex;
    align-items: center;
}
