@use "../Common";

.course__card {
    @extend .box-shadow-main;
    --var-card-img-width: 110px;

    width: 24rem;
    min-width: 250px;
    max-width: 100%;
    height: 100%;
    min-height: 18rem;
    border-top-right-radius: 25px;
    background: var(--css-var-card-bg);
    padding: 10px;
}

.course__card-title {
    font-size: 24px;
    font-weight: bold;
}

.course__card-sort {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #444444;
}

.course__card-text-block {
    width: 100%;
    height: 210px;
    overflow: hidden;
}

.course__card-extra-text-block {
    overflow: hidden;

    &.with-img {
        max-width: calc(100% - var(--var-card-img-width) - 10px);
    }
}

.course__card-img {
    max-height: 100%;
    max-width: var(--var-card-img-width);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}
