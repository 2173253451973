.notification__item {
    cursor: pointer;

    display: flex;
    align-items: center;
    border: 1px solid #000000;

    border-radius: 4px;

    gap: 10px;

    padding: 6px 8px;

    background: #ffffff;

    &.wrong {
        background-color: #f4b8b8;
        border-color: #ea7979;
    }

    &.viewed {
        opacity: 0.6;
    }

    overflow-wrap: break-word;

    @media (max-width: 767px) {
        & {
            flex-direction: column;
        }
    }
}

.notification__item-content {
    max-width: 100%;
    overflow-wrap: break-word;
}

.notification__item-button-block {
    max-width: 100%;
    overflow-wrap: break-word;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.notification__item-time-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 88px;
    min-width: 88px;
}

.notification__item-user-avatar {
    width: 48px;

    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: cover;
}
