@use "../../Common";

.student-assessment-task-title {
    line-height: 1;
    color: #868686;
}

.student-assessment-tasks {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.student-assessment-task__wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.student-assessment-view-task__wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.student-assessment-test {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.student-assessment-test__options {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.student-assessment-find-pair__item {
    @extend .prevent-select;

    padding: 4px 6px;
    border-radius: 6px;
    background-color: #ffffffaa;
    cursor: pointer;

    &.selected {
        background-color: #ffff7288;
    }
    &.done {
        background-color: #44cc4488;
    }
    &.right {
        text-align: end;
    }

    &.wrong {
        background-color: #f4b8b8;
    }
}

.student-assessment-find-pair__row {
    position: relative;
    display: contents;
    border-collapse: collapse;
}

.student-assessment-find-pair__col {
    position: relative;

    row-gap: 8px;
    column-gap: 12px;

    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, auto));
    grid-template-rows: auto;

    border-collapse: collapse;

    width: fit-content;
    align-self: center;
}

.student-assessment-sortable-order {
    display: flex;
    row-gap: 12px;
    column-gap: 8px;
    margin-top: 4px;

    width: fit-content;
    align-self: center;

    &.horizontal {
        flex-wrap: wrap;
    }

    &.vertical {
        flex-direction: column;
    }
}

.student-assessment-view-sortable-order__container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.student-assessment-view-sortable-order__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.student-assessment-view-sortable-order__item {
    @extend .dnd__sortable-item;
    display: flex;
    justify-content: center;
}

.student-assessment-fill-spaces-by-hand__input {
    @extend .input-small;

    min-width: 3rem;
    max-width: 240px;
    width: fit-content;
    text-align: center;

    &.wrong {
        background-color: #f4b8b8;
        border-color: #ea7979;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.good {
        border-color: #00cc00;
        background-color: #44cc4488;
    }

    &.wrong-good {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-color: #00cc00;
        background-color: #44cc4488;
    }
}

.student-assessment-fill-spaces__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 4px;
}

.student-assessment-fill-spaces {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 6px;
    row-gap: 4px;
}

.student-assessment-fill-spaces-exists__inputs {
    @extend .my-card;

    width: 100%;
    min-height: 80px;
    background-color: #ffffff66;
}

.student-assessment-classification__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 4px;
}

.student-assessment-classification__column-title {
    font-size: 1.1em;
}

.student-assessment-classification__answers-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 12px;
    row-gap: 16px;
}

.student-assessment-classification__card {
    @extend .my-card;

    display: flex;

    padding: 10px;
    gap: 8px;
    min-height: 64px;
    background-color: #ffffff66;

    &.inputs {
        flex: 1 0;

        overflow: hidden;
    }

    &.answers {
        flex-direction: column;
        flex: 0 1 0;
        min-width: max(fit-content 100px);
    }
}

.student-assessment-classification__hr {
    display: flex;
    flex-direction: column;
}

$item-border-size: 0px;
$item-padding: 6px;

.student-assessment-classification__item {
    @extend .prevent-select;

    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
    border: $item-border-size solid #000000;
    text-align: center;

    border-radius: 4px;

    margin: calc($item-border-size);
    padding: $item-padding;

    background: #ffffff;

    &.wrong {
        background-color: #f4b8b8;
        border-color: #ea7979;
    }

    &.good {
        border-color: #00cc00;
        background-color: #44cc4488;
    }
}

.student-assessment-classification__item-autosize {
    margin: 0 calc($item-border-size + 2px);
    padding: 0 $item-padding;
}

.student-assessment-open-question__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.student-assessment-view-open-question__container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.student-assessment-task__title-not-checked {
    background-color: #ffff00;
    border-color: #ffff00;
    border-radius: 4px;
    text-align: center;
    font-size: 20px;
    padding: 4px 6px;
}
