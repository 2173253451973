.lexisCard {
    margin: 10px auto 10px auto;
    min-height: 400px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #ffffff66;
}

div.lexisCardDiv {
    min-height: 320px;
    height: 100%;
}

div.lexisCardImgDiv {
    min-width: 260px;
    max-width: 260px;
}

.lexisCardImg {
    max-width: 280px;
    max-height: 300px;
}

.lexisCardLimit {
    min-height: 340px;
}

.lexisCardWord {
    font-size: 20px;
}

.lexisCardAnswer {
    min-height: 40px;
}

.clearFloat {
    clear: both;
}
