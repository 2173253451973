.bigCheck {
    margin-left: 2px;
}

.bigCheck > input {
    width: 1.2em;
    height: 1.2em;
}

.tableHeader {
    background-color: var(--css-var-table-header);
    font-size: 1.1em;
    font-weight: 500;
    color: #ffffff;
    padding: 8px;
}

.tableRow {
    padding: 4px 0px;
    border-bottom: 1px solid #888888;
}

.findPair {
    margin: 0 12px;
}

.fillSpacesResultAnswer {
    color: #888888;
    text-decoration: underline;
}

.classificationCol {
    padding: 10px;
    height: 100%;
    border: 1px solid #00000044;
    border-radius: 8px;
}
