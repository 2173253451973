.modal-dialog {
    width: 90vw;
}

.modal-content {
    min-height: 50vh;
    max-height: 90vh;
}

.modal-content-fullscreen {
    min-height: 100vh !important;
    max-height: 100vh !important;
}

.modal-body {
    overflow-y: scroll;
}

.modal-bg {
    background-color: #eaeaea;
}

.loading-modal-fs {
    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;
    position: absolute;

    background-color: #00000033;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}
