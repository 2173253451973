.inputFile {
    position: relative;
    margin: 0 auto;
    min-width: min(800px, 100%);
}

.inputFileBorderError {
    border: 1px solid #ff0000;
}

.inputFileBorderDefault {
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
    /* border: 0.5px solid #000000; */
}

.inputFilePrev {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: min(800px, 100%);
    aspect-ratio: 4/3;
    background-color: #ffffff88;
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;
}

.inputFilePrev:hover {
    background-color: #ffffff66;
}

.inputFilePrev:hover .inputFilePrevEdit {
    opacity: 95%;
}

.inputFilePrevEdit {
    opacity: 75%;
    position: absolute;
    text-shadow: #000000 0 0 10px;
    color: #ffffff;
}

.inputFileEmptyLabel {
    cursor: pointer;
    aspect-ratio: 4/3;
    width: 100%;
    min-width: min(300px, 100%);
    max-width: min(300px, 100%);
    background-color: #ffffff88;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.inputFileEmptyLabel:hover {
    opacity: 70%;
}

.inputFilePrevImg {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    border-radius: 10px;
}

.inputFilePrevAudio {
    display: flex;
    justify-content: center;

    max-width: min(100%, 300px);
    width: min(100%, 300px);
    padding: 0;

    margin: 0 auto;
}

.inputFilePrevAudio.audio {
    width: 100%;
}

.inputFilePrevAudioLabel {
    max-width: min(calc(100% - 40px), 260px);
    width: min(calc(100% - 40px), 260px);
    max-height: 60px;
    border-radius: 0 0 10px 10px;
    border-top: none;
}
