.teacherBoubleFooter {
    position: absolute;
    bottom: 16px;
}

.teacherBoubleTaskIcon {
    width: 40px;
    background-color: #ffffffaa;
    border-radius: 5px;
    padding: 4px;
}

.teacherBoublePlusWrapper {
    cursor: pointer;
}

.teacherBoublePlus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
