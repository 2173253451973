.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-wrap-break-word {
    overflow-wrap: break-word;
}

.auth-alter-link {
    color: #5f4b8b;
}

.md-last-pad-zero > :last-child {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    margin-block-end: 0 !important;
}
