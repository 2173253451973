.my-card-base {
    border-radius: 16px;
    padding: 16px;
    background-color: var(--css-var-bg);
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
    // box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.2);
}

.my-card {
    @extend .my-card-base;

    width: 100%;
    min-height: 100px;
}

.my-card-header {
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
    display: flex;
}

.my-card-header-title {
    font-size: 20px;
    font-weight: 500;
}

.my-card-body {
    padding-top: 16px;
}
