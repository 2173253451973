@use "../../Common";

.student-lexis-find-pair-item {
    @extend .prevent-select;

    width: fit-content;
    padding: 4px 8px;
}

$student-lexis-scramble__item-border-radius: 8px;

.student-lexis-scramble__item {
    @extend .prevent-select;

    width: 48px;
    height: 48px;
    padding: 8px 8px;
    border: 1px solid #b8b8b8;
    background-color: #ffffff66;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &-selected {
        @extend .student-lexis-scramble__item;
    }

    &-selected:first-child {
        @extend .student-lexis-scramble__item;
        border-top-left-radius: $student-lexis-scramble__item-border-radius;
        border-bottom-left-radius: $student-lexis-scramble__item-border-radius;
    }

    &-selected:last-child {
        @extend .student-lexis-scramble__item;
        border-top-right-radius: $student-lexis-scramble__item-border-radius;
        border-bottom-right-radius: $student-lexis-scramble__item-border-radius;
    }

    &:hover {
        opacity: 0.6;
    }
}

.student-lexis-nav__item {
    @extend .box-shadow-main-mini;
    @extend .prevent-select;

    background-color: #ffffff22;
    font-size: 12px;
    border-radius: 8px;
    padding: 10px 8px 8px 8px;
    width: 70px;
    height: 70px;
    position: relative;
    cursor: pointer;

    &.selected {
        background-color: #00ff0044;
    }

    &:hover {
        opacity: 0.8;
    }
}

input.student-lexis-space__input {
    width: 2.5rem !important;
}
