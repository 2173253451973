@use "../Common";

.lesson__card {
    --var-card-img-width: 120px;
    margin: 40px 0;
    padding: 0;
    min-height: 170px;
    height: 170px;
}

.lesson__card-inner {
    @extend .box-shadow-main;

    cursor: pointer;

    padding: 10px;
    border-top-right-radius: 25px;
    background: var(--css-var-card-bg);
    height: 100%;
    position: relative;
}

.lesson__card-title {
    font-size: 20px;
    font-weight: bold;
}

.lesson__card-img {
    max-height: 120px;
    max-width: var(--var-card-img-width);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-10px, -50%);
}

.lesson__card-text-block {
    height: 110px;
    overflow: hidden;

    &.with-img {
        max-width: calc(100% - var(--var-card-img-width) - 10px);
    }
}

.lesson__card-sort {
    position: absolute;
    right: 10px;
    bottom: 0;
    color: #444444;
}
