.btn-violet {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #5f4b8b;
    --bs-btn-border-color: #5f4b8b;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #4a3b6d;
    --bs-btn-hover-border-color: #4a3b6d;
    --bs-btn-focus-shadow-rgb: 95, 75, 139;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #4a3b6d;
    --bs-btn-active-border-color: #4a3b6d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #5f4b8b;
    --bs-btn-disabled-border-color: #5f4b8b;
}

.btn-success {
    --bs-btn-bg: #6bb549db;
    // #ecabd0;
}

.btn-danger {
    --bs-btn-bg: #ecabd0;
}

.btn-primary {
    --bs-btn-bg: #70d7ff;
}
