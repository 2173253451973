// @use "bootstrap";

@use "Common";
@use "Activity";

@use "NavBar";
@use "Course";
@use "Lesson";
@use "Dictionary";

.ap-japanesefont {
    font-family: "APJapanesefontImportant";
}
