div.Violet {
    --css-var-bg: #e1d8e8;
    --css-var-block: #bface0;
    --css-var-title: #a084ca;
    --css-var-other: #a084ca;

    --css-var-card-bg: #ffffff44;

    --css-var-table-header: #1976d2;
    --css-var-table-header-border: #1565c0;
    --css-var-table-border: #d9d9d9;
    --css-var-table-row-bg: #f4f2f1;
}
