.auth-form {
    width: 100%;
    max-width: 480px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    margin-top: auto;
    margin-bottom: auto;
}

.big-check {
    margin-left: 2px;
}

.big-check > input {
    width: 1.2em;
    height: 1.2em;
}

.check-wrong {
    background-color: #f09393;
}

.check-good {
    background-color: #b8f4b8;
}

.input-wrong {
    background-color: #f4b8b8;
}

.input-good {
    background-color: #b8f4b8;
}

.input-small {
    padding: 0 6px;
}

.input-border {
    border: 1px solid #000000;
}
