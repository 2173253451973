div {
    box-sizing: border-box;
}

.table-container {
    display: block;
    margin: 2em auto;
    width: 90%;
}

.table-img {
    max-width: 100%;
    max-height: 100px;
}

.flag-icon {
    margin-right: 0.1em;
}

.flex-table {
    display: flex;
    flex-flow: row wrap;
    border-left: solid 1px #d9d9d9;
    transition: 0.5s;
}
.flex-table:first-of-type {
    border-top: solid 1px #8a15c0;
    border-left: solid 1px #8a15c0;
}
.flex-table:first-of-type .flex-row {
    background: #a119d2;
    color: white;
    border-color: #9e15c0;
}

.flex-table.flex-table-row .flex-row {
    background: #f4f2f177;
}

.flex-table.flex-table-row:nth-child(odd) .flex-row {
    background: #f4f2f1cc;
}
.flex-table:hover {
    background: #f5f5f5;
    transition: 500ms;
}

.flex-row {
    width: 25%;
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;
}
.flex-row.first {
    width: 25%;
}

.rowspan {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
}

.column {
    display: flex;
    flex-flow: column wrap;
    width: 75%;
    padding: 0;
}
.column .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px #d9d9d9;
}
.column .flex-row:hover {
    background: #f5f5f5;
    transition: 500ms;
}

.flex-cell {
    width: calc(100% / 3);
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px #d9d9d9;
}

@media (max-width: 767px) {
    .flex-row {
        width: calc(100% / 3);
    }
    .flex-row.first {
        width: 100%;
    }
    .column {
        width: 100%;
    }
}

@media (max-width: 430px) {
    .flex-table .flex-row {
        border-bottom: 0;
    }
    .flex-table .flex-row:last-of-type {
        border-bottom: solid 1px #d9d9d9;
    }
    .header .flex-row {
        border-bottom: solid 1px;
    }
    .flex-row {
        width: 100%;
    }
    .flex-row.first {
        width: 100%;
        border-bottom: solid 1px #d9d9d9;
    }
    .column {
        width: 100%;
    }
    .column .flex-row {
        border-bottom: solid 1px #d9d9d9;
    }
    .flex-cell {
        width: 100%;
    }
}
