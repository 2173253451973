.notificationsBlock {
    cursor: pointer;
}

.profileBlock {
    cursor: pointer;
    position: relative;
}

.profileBlock:hover {
    opacity: 80%;
}

.profileImgWrapper {
    width: 40px;
    height: 40px;
}

.profileImgWrapper:hover {
}

.profileImg {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: cover;
}
