.navItem {
    border: 1px solid #000000;
    font-size: 12px;
    border-radius: 8px;
    padding: 10px 8px 8px 8px;
    width: 70px;
    height: 70px;
    position: relative;
    cursor: pointer;
}

.navBadge {
    position: absolute;
    top: 0;
    right: 0;
    margin: -8px -8px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.navBadgeZero {
    background-color: #19875488;
}

.navBadgeOther {
    background-color: #ff0000;
}
